import PAGE_SEGMENTS_FRAGMENT from './fragments/pageSegments'
import NAVIGATION_BAR_FRAGMENT from './fragments/nagivationBar'

const GLOBALS_QUERY = `
  query GlobalsQuery(
    $siteHandle: String! 
  ) {
    footer: globalSets(
      site: [$siteHandle]
      handle: "footer"
    ) {
      ...on footer_GlobalSet {
        ${ PAGE_SEGMENTS_FRAGMENT }
      }
    }

    header: globalSets(
      site: [$siteHandle]
      handle: "header"
    ) {
      ...on header_GlobalSet {
        ${ NAVIGATION_BAR_FRAGMENT }
      }
    }

    gtm: globalSets(
      site: [$siteHandle]
      handle: "tagManagerCookieBanner"
    ) {
      ...on tagManagerCookieBanner_GlobalSet {
        tagManagerId
      }
    }
    
    cookieBanner: globalSets(
      site: [$siteHandle]
      handle: "tagManagerCookieBanner"
    ) {
      ...on tagManagerCookieBanner_GlobalSet {
        tagManagerId
        cookieBanner {
          ...on cookieBanner_BlockType {
            titleCopy
            bodyCopy
            acceptButtonLabel
            rejectButtonLabel
          }
        }
      }
    }

    companyInfo: globalSet(
      handle: "companyInfo"
    ) {
      ...on companyInfo_GlobalSet {
        companyTitle
        companyAddress
      }
    }

    businessHours: globalSet(
      site: [$siteHandle]
      handle: "businessHours"
    ) {
      ...on businessHours_GlobalSet {
        openSign
        openingTimes {
          day
          time
        }
      }
    }
  }
`

export default GLOBALS_QUERY
