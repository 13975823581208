import type { AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { getUrl } from '../lib/urls'
import ContextProviders from '../components/ContextProviders'
import Gtm from '../components/Gtm'
import '../components/gridBrand/styles/fonts.css'

function BrixelApp( { Component, pageProps, hostname }: AppProps<{
  entry?: any
}> & { hostname: string } ) {
  const {
    locales, 
    defaultLocale, 
    asPath, 
    locale: currentlLocale, 
  } = useRouter()
  const iconDir = hostname?.indexOf( 'gogrid' ) > -1 ? '/grid' : ''
  const iconThemeColor = hostname?.indexOf( 'gogrid' ) > -1 ? '#a5d9d0' : '#009877'

  let canonicalUrl = null

  if ( 
    pageProps?.entry?.canonicalUrl 
    && new URL( pageProps.entry.canonicalUrl, `https://${ hostname }` ).hostname === hostname
  ) {
    canonicalUrl = getUrl( hostname, currentlLocale, defaultLocale, pageProps.entry.canonicalUrl )
  }

  return (
    <ContextProviders 
      hostname={ hostname }
      pageProps={ pageProps }
    >
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href={ `${ iconDir }/apple-touch-icon.png` } />
        <link rel="icon" type="image/png" sizes="32x32" href={ `${ iconDir }/favicon-32x32.png` } />
        <link rel="icon" type="image/png" sizes="16x16" href={ `${ iconDir }/favicon-16x16.png` } />
        <link rel="manifest" href={ `${ iconDir }/site.webmanifest` } />
        <link rel="mask-icon" href={ `${ iconDir }/safari-pinned-tab.svg` } color={ iconThemeColor } />
        <meta name="msapplication-TileColor" content={ iconThemeColor } />
        <meta name="theme-color" content={ iconThemeColor } />
        { locales.map( locale => (
          <link 
            key={ locale }
            rel="alternate" 
            hrefLang={ locale }
            href={ getUrl( hostname, locale, defaultLocale, asPath ) }
          />
        ) ) }
        { canonicalUrl && (
          <link 
            key="canonical"
            rel="canonical" 
            href={ canonicalUrl }
          />
        ) }
      </Head>
      <Gtm />
      <Component 
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...pageProps } 
      />
    </ContextProviders>
  )
}

BrixelApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps( appContext )

  // eslint-disable-next-line no-nested-ternary
  const hostname = appContext.ctx.req
    ? appContext.ctx.req.headers.host
    : typeof window !== 'undefined'
      ? window.location.host
      : null

  return {
    ...appProps,
    hostname,
  }
}

export default BrixelApp
