import IMAGE_FRAGMENT from './image'

const HIGHLIGHTS_FRAGEMENT = `
... on highlights_images_BlockType {
  id
  typeHandle
  highlightImages {
    ${ IMAGE_FRAGMENT }
  }
  highlightEyebrow
  highlightTitle
  highlightDescription
}
... on highlights_threeDeeTour_BlockType {
  id
  typeHandle
  highlightEyebrow
  highlightTourUrl
  highlightButtonText
  highlightTitle
  highlightDescription
  highlightPreviewImage {
    ${ IMAGE_FRAGMENT }
  }
}
`

export default HIGHLIGHTS_FRAGEMENT
