import IMAGE_FRAGMENT from '../image'

const PARTNER_LOGOS_FRAGMENT = `
...on pageSegments_partnerLogos_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  partnerLogos {
    ...on partnerLogos_BlockType {
      id
      label
      logoLink
      logo {
        ...on brixelCms_Asset {
          ${ IMAGE_FRAGMENT }
        }
      }
    }
  }
}
`

export default PARTNER_LOGOS_FRAGMENT
