const NAVIGATION_BAR_FRAGMENT = `
navigationBar {
  ...on navigationBar_button_BlockType {
    id
    typeHandle
    linkIt {
      url
      text
      target
    }
    buttonStyle
    buttonColor
  }
  ...on navigationBar_textLink_BlockType {
    id
    typeHandle
    linkIt {
      url
      text
      target
    }
  }
  ...on navigationBar_dropdown_BlockType {
    id
    typeHandle
    label
    icon
    dropdownList {
      ...on dropdownList_BlockType {
        id
        linkIt {
          url
          text
          target
        }
        withSeparator
      }
    }
  }
}
`

export default NAVIGATION_BAR_FRAGMENT
