const FOOTER_FRAGMENT = `
...on pageSegments_footer_Entry {
  id
  anchorId
  typeHandle
  bodyText
  hideLanguagePicker
}
`

export default FOOTER_FRAGMENT
