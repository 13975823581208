export function LightBulb() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.91711 11.8828C6.91711 8.76346 9.5087 6.17188 12.628 6.17188C15.7473 6.17188 18.3389 8.76346 18.3389 11.8828C18.3389 13.4485 17.7088 14.8061 16.6763 15.8386C15.9711 16.5437 15.5648 17.5822 15.5648 18.6331V20.2051C15.5648 21.5675 14.4527 22.6795 13.0903 22.6795H12.1656C10.8033 22.6795 9.69123 21.5675 9.69123 20.2051V18.6331C9.69123 17.5876 9.28895 16.5542 8.59038 15.8493C7.52962 14.8871 6.91711 13.4232 6.91711 11.8828ZM12.628 7.42188C10.1991 7.42188 8.16711 9.45382 8.16711 11.8828C8.16711 13.1101 8.65918 14.2296 9.43975 14.9321C9.44789 14.9394 9.45584 14.9469 9.46358 14.9547C10.4229 15.914 10.9412 17.2797 10.9412 18.6331V20.2051C10.9412 20.8771 11.4936 21.4295 12.1656 21.4295H13.0903C13.7623 21.4295 14.3148 20.8771 14.3148 20.2051V18.6331C14.3148 17.2797 14.8331 15.914 15.7924 14.9547C16.6093 14.1378 17.0889 13.0912 17.0889 11.8828C17.0889 9.45382 15.0569 7.42188 12.628 7.42188Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.628 21.4294C12.9732 21.4294 13.253 21.7093 13.253 22.0544V22.9791C13.253 23.3243 12.9732 23.6041 12.628 23.6041C12.2828 23.6041 12.003 23.3243 12.003 22.9791V22.0544C12.003 21.7093 12.2828 21.4294 12.628 21.4294Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.69122 18.3557C9.69122 18.0105 9.97105 17.7307 10.3162 17.7307H14.9398C15.2849 17.7307 15.5648 18.0105 15.5648 18.3557C15.5648 18.7009 15.2849 18.9807 14.9398 18.9807H10.3162C9.97105 18.9807 9.69122 18.7009 9.69122 18.3557Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.863 11.8828C19.863 11.5376 20.1428 11.2578 20.488 11.2578H21.4127C21.7579 11.2578 22.0377 11.5376 22.0377 11.8828C22.0377 12.228 21.7579 12.5078 21.4127 12.5078H20.488C20.1428 12.5078 19.863 12.228 19.863 11.8828Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.628 2.47314C12.9732 2.47314 13.253 2.75297 13.253 3.09814V4.02285C13.253 4.36803 12.9732 4.64785 12.628 4.64785C12.2828 4.64785 12.003 4.36803 12.003 4.02285V3.09814C12.003 2.75297 12.2828 2.47314 12.628 2.47314Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.99051 5.15278C6.23459 4.90871 6.63031 4.90871 6.87439 5.15278L7.52169 5.80008C7.76576 6.04416 7.76576 6.43988 7.52169 6.68396C7.27761 6.92804 6.88188 6.92804 6.6378 6.68396L5.99051 6.03667C5.74643 5.79259 5.74643 5.39686 5.99051 5.15278Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.2809 5.15278C19.0368 4.90871 18.6411 4.90871 18.397 5.15278L17.7497 5.80008C17.5056 6.04416 17.5056 6.43988 17.7497 6.68396C17.9938 6.92804 18.3895 6.92804 18.6336 6.68396L19.2809 6.03667C19.5249 5.79259 19.5249 5.39686 19.2809 5.15278Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.21829 11.8828C3.21829 11.5376 3.49811 11.2578 3.84329 11.2578H4.768C5.11318 11.2578 5.393 11.5376 5.393 11.8828C5.393 12.228 5.11318 12.5078 4.768 12.5078H3.84329C3.49811 12.5078 3.21829 12.228 3.21829 11.8828Z" />
    </svg>
  )
}
