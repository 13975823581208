import { useCraft } from '../lib/craft'
import GtmScripts from '../lib/gtm'

function Gtm() {
  const craftData = useCraft()
  const gtmCode = craftData?.pageProps?.gtm?.[ 0 ]?.tagManagerId
  return <GtmScripts gtmCode={ gtmCode } />
}

export default Gtm
