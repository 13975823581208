import {
  createContext, useContext, useMemo, useState, 
} from 'react'
import request from './request'
import MetaTags from './MetaTags'
import GLOBALS_QUERY from '../../graphql/globals'

const CraftContext = createContext( null )
CraftContext.displayName = 'CraftContext'

export const useCraft = () => useContext( CraftContext )

export function CraftProvider( {
  hostname, pageProps, MetaTagsComponent = null, children, 
} ) {
  const [clientSideRequestProps, setClientSideRequestProps] = useState( {} )

  const clientSideRequest = ( query = null, variables = {} ) => {
    getCraftData( query, variables )
      .then( ( { props } ) => setClientSideRequestProps( props ) )
  }

  const value = useMemo( () => ( {
    pageProps: { 
      ...pageProps, 
      ...clientSideRequestProps, 
    },
    clientSideRequest,
  } ), [pageProps, clientSideRequestProps] )

  const metaTags = () => {
    if ( !pageProps?.entry ) {
      return null
    }

    if ( MetaTagsComponent ) {
      return <MetaTagsComponent entry={ pageProps.entry } hostname={ hostname } />
    }

    return <MetaTags metaTags={ pageProps.entry?.metaTags } />
  }

  return (
    <CraftContext.Provider value={ value }>
      { metaTags() }
      { children }
    </CraftContext.Provider>
  )
}

export async function getServerSideCraftData( { 
  query,
  variables, 
  options = {},
  passThru = {}, 
}: {
  query; 
  variables; 
  options?: {
    previewToken?: string;
  };
  passThru?: {};
} ): Promise<{ props: any, notFound?: boolean }> {
  const [queryResponse, globalsResponse] = await Promise.all( [
    request( query, variables, options ),
    request( GLOBALS_QUERY, { siteHandle: variables.siteHandle } ),
  ] )

  if ( !queryResponse?.data?.entry ) {
    return {
      notFound: true,
      props: {
        ...passThru,
        ...globalsResponse.data,
      },
    }
  }

  return {
    props: {
      ...passThru,
      ...queryResponse.data,
      ...globalsResponse.data,
    },
  }
}

export const getCraftData = ( query = null, variables = {}, passThru = {} ) => {
  const requests = [request( GLOBALS_QUERY )]

  if ( query ) {
    requests.push( request( query, variables ) )
  }

  return Promise.all( requests )
    .then( ( [queryResponse, globalsResponse] ) => ( {
      props: { 
        ...passThru,
        ...queryResponse.data, 
        ...globalsResponse.data,
      },
    } ) )
}
