import IMAGE_FRAGMENT from '../image'

const TESTIMONIALS_FRAGMENT = `
...on pageSegments_testimonials_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  testimonials {
    ...on testimonials_default_Entry {
      id
      title
      description
      amountSaved
      mainImage {
        ${ IMAGE_FRAGMENT }
      }
    }
  }
}
`

export default TESTIMONIALS_FRAGMENT
