import NextLink from 'next/link'
import { useEffect, useState } from 'react'

function Link( {
  children, 
  href, 
  target = null, 
  rel = null, 
  disabled = false,
  ...props
} ) {
  const isExternalURL = useIsExternalURL( href )

  if ( disabled ) {
    return (
      <span>{ children }</span>
    )
  }

  if ( target === '_blank' || isExternalURL ) {
    return (
      <a 
        href={ href }
        target={ target || '_blank' }
        rel={ rel }
      >
        { children }
      </a>
    )
  }

  if ( !href ) {
    throw new Error( 'No href passed to Link' )
  }
  
  return (
    <NextLink
    // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
      href={ href }
      // scroll={ false }
    >
      <a>
        { children }
      </a>
    </NextLink>
  )
}

export const useIsExternalURL = url => {
  const [isExternal, setIsExternal] = useState( true )

  useEffect( () => {
    if ( typeof window !== 'undefined' ) {
      if ( !( /^(?:[a-z]+:)?\/\//i ).test( url ) ) {
        setIsExternal( false )
        return
      }

      const { host } = window.location
      setIsExternal( new URL( url, `https://${ host }` ).hostname !== host )
    }
  }, [url] )

  return isExternal
}

export default Link
