import IMAGE_FRAGMENT from '../image'

const CARDS_FRAGMENT = `
...on pageSegments_cards_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  alignment
  mainImage {
    ${ IMAGE_FRAGMENT }
  }
  cards {
    ...on cards_BlockType {
      id
      cardTitle
      cardDescription
      cardImage {
        ${ IMAGE_FRAGMENT }
      }
      cardImageAlignment
      cardButtonLabel
      cardButtonLink
      cardColor
    }
  }
}
`

export default CARDS_FRAGMENT
