import Script from 'next/script'
import { useCallback, useEffect, useRef } from 'react'

export type ConsentObject = {
  adConsentGranted: boolean,
  analyticsConsentGranted: boolean,
  functionalityConsentGranted: boolean,
  personalizationConsentGranted: boolean,
  securityConsentGranted: boolean,
  [key: string]: boolean,
}

declare global {
  interface Window {
    addConsentListener: ( callback: ( consent: ConsentObject ) => void ) => void
  }
}

const initalConsentScript = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('consent', 'default', {
  'ad_storage': 'denied',
  'analytics_storage': 'denied',
});
`

const headScript = gtmCode => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${ gtmCode }');
`

const bodyScript = gtmCode => `
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${ gtmCode }"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
`

export const useConsentListener = () => {
  const consentListeners = useRef( [] )
  
  useEffect( () => {
    window.addConsentListener = callback => {
      consentListeners.current.push( callback )
    }
  }, [] )

  const onConsentChange = useCallback( ( consent: ConsentObject ) => {
    consentListeners.current.forEach( callback => {
      callback( consent )
    } )
  }, [] )

  return onConsentChange
}

function GtmScripts( { gtmCode } ) {
  if ( !gtmCode ) {
    return null 
  }
  
  return (
    <>
      <div 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: bodyScript( gtmCode ) } }
      />
      <Script
        id="initial-consent"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={ { __html: initalConsentScript } }
      />
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={ { __html: headScript( gtmCode ) } }
      />
    </>
  )
}

export default GtmScripts

export const grantAnalytics = () => {
  if ( typeof window !== 'undefined' && window.gtag ) {
    window.gtag( 'consent', 'update', {
      analytics_storage: 'granted',
    } )
  }
}

export const denyAnalytics = () => {
  if ( typeof window !== 'undefined' && window.gtag ) {
    window.gtag( 'consent', 'update', {
      analytics_storage: 'denied',
    } )
  }
}
