import IMAGE_FRAGMENT from '../image'

const ACCORDION_FRAGMENT = `
...on pageSegments_accordion_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  accordionStyle
  accordionLayout
  buttonLabel
  buttonLink
  buttonIcon
  mainImage {
    ${ IMAGE_FRAGMENT }
  }
  accordion {
    ...on accordion_BlockType {
      id
      heading
      itemContent
    }
  }
}
`

export default ACCORDION_FRAGMENT
