import Head from 'next/head'

interface MetaTags {
  metaTags: Array<{
    name: string,
    value: string,
  }>,
}

function MetaTags( { metaTags }: MetaTags ) {
  return (
    <Head>
      { metaTags.map( tag => {
        if ( !tag.value ) {
          return null
        }
        
        if ( tag.name === 'title' ) {
          return <title key="title">{ tag.value }</title>
        }

        return <meta key={ tag.name } property={ tag.name } content={ tag.value } />
      } ) }
    </Head>
  )
}

export default MetaTags
