import IMAGE_FRAGMENT from '../image'

const CONTACT_FRAGMENT = `
...on pageSegments_contact_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  contactCards {
    ...on contactCards_BlockType {
      id
      icon {
        ${ IMAGE_FRAGMENT }
      }
      text
      linkText
      linkDestination
    }
  }
}
`

export default CONTACT_FRAGMENT
