import IMAGE_FRAGMENT from '../image'

const PROPERTIES = `
...on pageSegments_properties_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  properties {
    ...on properties_BlockType {
      id
      propertyUrl
      propertyTitle
      location
      price
      rooms
      area
      yearBuilt
      exclusivityEndsAt
      propertyImage {
        ${ IMAGE_FRAGMENT }
      }
    }
  }
}
`

export default PROPERTIES
