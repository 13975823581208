const PROPERTY_LISTINGS_FRAGMENT = `
...on pageSegments_propertyListings_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  buttonLabel
  buttonLink
  company
  related
  numberOfProperties
  showCountdown
  propertyCardStyle
  carouselForSmallScreens
  showLoadMoreButton
  showFilterControls
}
`

export default PROPERTY_LISTINGS_FRAGMENT
