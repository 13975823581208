import Cookies from 'js-cookie'

const cookieName = 'cookie-consent'

const defaultOptions = {
  secure: true,
  expires: 365,
  sameSite: 'Lax',
}

export const setCookie = ( userPreferences, options = {} ) => {
  const payload = JSON.stringify( userPreferences )
  Cookies.set( cookieName, payload, { ...defaultOptions, ...options } )
}

export const getCookie = () => {
  const cookieString = Cookies.get( cookieName )
  return cookieString ? JSON.parse( cookieString ) || null : null
}
