import IMAGE_FRAGMENT from '../image'

const INFO_POINTS_FRAGMENT = `
...on pageSegments_infoPoints_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  infoPointsAlignment
  infoPoints {
    ...on infoPoints_BlockType {
      id
      infoPointTitle
      infoPointDescription
      infoPointIcon {
        ${ IMAGE_FRAGMENT }
      }
    }
  }
}
`

export default INFO_POINTS_FRAGMENT
