import IMAGE_FRAGMENT from '../image'

const LOCAL_AREA_FRAGMENT = `
...on pageSegments_localArea_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  location {
    lat
    lng
    address
  }
  distances {
    ... on distances_BlockType {
      id
      place
      distance
    }
  }
  mainImage {
    ${ IMAGE_FRAGMENT }
  }
  subtitle
  bodyText
}
`

export default LOCAL_AREA_FRAGMENT
