import IMAGE_FRAGMENT from '../image'

const POSTCARDS_FRAGMENT = `
...on pageSegments_postcards_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  postcards {
    ...on postcards_BlockType {
      id
      number
      tag
      heading
      bodyText
      cardColor
      image {
        ${ IMAGE_FRAGMENT }
      }
    }
  }
}
`

export default POSTCARDS_FRAGMENT
