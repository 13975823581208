import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { IntlProvider as IP } from 'react-intl'
import en from '../compiled-lang/en.json'
import de from '../compiled-lang/de.json'
import fr from '../compiled-lang/fr.json'
import it from '../compiled-lang/it.json'
import pt from '../compiled-lang/pt.json'

function IntlProvider( { children } ) {
  const { locale, defaultLocale } = useRouter()
  const l = locale || defaultLocale || 'de'
  const fullLocale = ['en', 'de', 'fr', 'it'].includes( l ) ? `${ l }-CH` : l

  const messages = useMemo( () => {
    switch ( l ) {
      case 'en':
        return en
      case 'de':
        return de
      case 'fr':
        return fr
      case 'it':
        return it
      case 'pt':
        return pt
      default:
        return en
    }
  }, [l] )

  return (
    <IP 
      locale={ fullLocale } 
      defaultLocale={ defaultLocale }
      messages={ messages }
    >
      { children }
    </IP>
  )
}

export default IntlProvider
