import IMAGE_FRAGMENT from '../image'

const FLOORPLAN_SEGMENT = `
...on pageSegments_floorplan_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  mainImage {
    ${ IMAGE_FRAGMENT }
  }
  floorplanTable {
    ... on floorplanTable_BlockType {
      id
      floor
      apartment
      numberOfRooms
      livingArea
      balcony
      rent
      extraCosts
      availability
    }
  }
  floorplanFooter {
    ... on floorplanFooter_BlockType {
      id
      sectionTitle
      options {
        ... on options_list_BlockType {
          id
          typeHandle
          listIcon
          listTitle
          listContent
        }
      }
    }
  }
}
`

export default FLOORPLAN_SEGMENT
