import HIGHLIGHTS_FRAGEMENT from '../highlights'

const HIGHLIGHTS_PAGE_SEGMENT_FRAGMENT = `
...on pageSegments_highlights_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  highlights {
    ${ HIGHLIGHTS_FRAGEMENT }
  }
}
`

export default HIGHLIGHTS_PAGE_SEGMENT_FRAGMENT
