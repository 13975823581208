export function Document() {
  return (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 22.0536C20 22.5983 19.7836 23.1208 19.3984 23.506C19.0132 23.8912 18.4907 24.1076 17.9459 24.1076H3.05405C2.50929 24.1076 1.98683 23.8912 1.60162 23.506C1.21641 23.1208 1 22.5983 1 22.0536V3.05357C1 2.5088 1.21641 1.98634 1.60162 1.60113C1.98683 1.21592 2.50929 0.999512 3.05405 0.999512H13.3243L20 7.67519V22.0536Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 7.67519H15.3784C14.8336 7.67519 14.3112 7.45878 13.926 7.07357C13.5407 6.68836 13.3243 6.1659 13.3243 5.62113V0.999512L20 7.67519Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.1486 10.7563H4.85132" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.1486 14.0122H4.85132" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.1486 17.2573H4.85132" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.2535 20.5132H4.85132" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
