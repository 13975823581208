const TEXT_CTA_FRAGMENT = `
...on pageSegments_textCta_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  ctaTheme
  ctaIcon
  buttonGroup {
    ...on buttonGroup_BlockType {
      id
      buttonLink
      buttonLabel
      primary
      openInNewTab
    }
  }
}
`

export default TEXT_CTA_FRAGMENT
