import IMAGE_FRAGMENT from '../image'

const VIDEO_CTA_FRAGMENT = `
...on pageSegments_videoCta_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  description
  buttonLabel
  videoUrl
  mainImage {
    ${ IMAGE_FRAGMENT }
  }
}
`

export default VIDEO_CTA_FRAGMENT
