import { useRouter } from 'next/router'
import { getUrl } from '../lib/urls'
import MetaTags from '../lib/craft/MetaTags'
import { imageUrl } from './Image'

function BrixelMetaTags( { hostname, entry } ) {
  const router = useRouter()

  if ( !entry ) {
    return null
  }

  const tags = getTags( hostname, entry, router )
  return <MetaTags metaTags={ tags } />
}

const getTags = ( hostname, entry, router ) => {
  const { locale, defaultLocale, asPath } = router
  const url = getUrl( hostname, locale, defaultLocale, asPath )
  const metaTags = entry?.metaTags?.[ 0 ]
  const title = metaTags?.metaTitle || entry.mainTitle || entry.title || 'Brixel'
  const description = metaTags?.metaDescription || ''
  const imgUrl = metaTags?.metaImage[ 0 ]?.url

  const ogImage = imgUrl && imageUrl( {
    src: imgUrl,
    width: 1200,
    height: 630,
  } )
  
  const twitterImage = imgUrl && imageUrl( {
    src: imgUrl,
    width: 1200,
    height: 675,
  } )

  return [
    // Primary Meta Tags
    {
      name: 'title',
      value: title,
    },
    {
      name: 'description',
      value: description,
    },
    // Open Graph / Facebook 
    {
      name: 'og:type',
      value: 'website',
    },
    {
      name: 'og:url',
      value: url,
    },
    {
      name: 'og:title',
      value: title,
    },
    {
      name: 'og:description',
      value: description,
    },
    {
      name: 'og:image',
      value: ogImage,
    },
    // Twitter
    {
      name: 'twitter:card',
      value: 'summary_large_image',
    },
    {
      name: 'twitter:url',
      value: url,
    },
    {
      name: 'twitter:title',
      value: title,
    },
    {
      name: 'twitter:description',
      value: description,
    },
    {
      name: 'twitter:image',
      value: twitterImage,
    },
  ]
}

export default BrixelMetaTags
