import IMAGE_FRAGMENT from '../image'

const EXPERTS_FRAGMENT = `
...on pageSegments_experts_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  experts {
    ...on experts_default_Entry {
      id
      title
      mainImage {
        ${ IMAGE_FRAGMENT }
      }
    }
  }
}
`

export default EXPERTS_FRAGMENT
