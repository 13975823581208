import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import ArrowIcon from './icons/ArrowIcon'
import Link from './Link'
import { Support } from './icons/small'

export type ButtonColors = 'green' | 'red' | 'blue' | 'black' | 'darkGreen'
type ButtonSizes = 'normal' | 'small'

interface ButtonBase {
  children: ReactNode,
}

interface StyledButton {
  color?: ButtonColors,
  outline?: boolean,
  size?: ButtonSizes,
  fullWidth?: boolean,
  icon?: 'rightArrow' | 'support',
  children: ReactNode,
}

interface ButtonHref extends ButtonBase {
  onClick?: never,
  href: string,
  target?: '_self' | '_blank'
}

interface ButtonOnClick extends ButtonBase {
  onClick: () => void,
  href?: never,
  target?: never
}

interface ButtonSubmit extends ButtonBase {
  onClick?: never,
  href?: never,
  target?: never
}

export type TUnstyledButton = ButtonHref | ButtonOnClick | ButtonSubmit
export type TButton = TUnstyledButton & StyledButton

function ButtonContents( { label, icon } ) {
  switch ( icon ) {
    case 'rightArrow':
      return (
        <>
          <span>{ label }</span>
          <ArrowIcon />
        </>
      )
    case 'support':
      return (
        <>
          <Support />
          <span>{label}</span>
        </>
      )

    default: return label
  }
}

function Button( { 
  color = 'green',
  outline = false,
  size = 'normal',
  fullWidth = false,
  icon = null,
  href = null,
  target = null,
  onClick = null,
  children, 
}: TButton ) {
  return (
    <StyledButton
      $color={ color }
      $style={ outline ? 'outline' : 'filled' }
      $size={ size }
      $fullWidth={ fullWidth }
    >
      { onClick 
        ? (
          <UnstyledButton
            onClick={ onClick }
          >
            <ButtonContents label={ children } icon={ icon } />
          </UnstyledButton>
        )
        : (
          <UnstyledButton
            href={ href }
            target={ target }
          >
            <ButtonContents label={ children } icon={ icon } />
          </UnstyledButton>
        )}
      
    </StyledButton>
  )
}

export function UnstyledButton( {
  onClick = null,
  href = null,
  target = null,
  children,
}: TUnstyledButton ) {
  if ( onClick ) {
    return (
      <button
        type="button"
        onClick={ onClick }
      >
        { children }
      </button>
    )
  }

  if ( href ) {
    return (
      <Link 
        href={ href }
        target={ target }
      >
        { children }
      </Link>
    )
  }

  return (
    <button type="submit">
      { children }
    </button>
  )
}

export const StyledButton = styled.span<{
  $color: ButtonColors,
  $style?: 'filled' | 'outline',
  $size?: ButtonSizes,
  $fullWidth?: boolean,
}>`
  a,
  button {
    --button-hover-text-color: ${ p => p.theme.colors.realWhite };

    ${ p => p.$color === 'green' && css`
      --button-color: ${ p.theme.colors.green400 };
      --button-hover-color: ${ p.theme.colors.green700 };
      --button-hover-border-color: ${ p.theme.colors.green700 };
    ` }
    
    ${ p => p.$color === 'red' && css`
      --button-color: ${ p.theme.colors.red400 };
      --button-hover-color: ${ p.theme.colors.red700 };
      --button-hover-border-color: ${ p.theme.colors.red700 };
    ` }

    ${ p => p.$color === 'blue' && css`
      --button-color: ${ p.theme.colors.blue700 };
      --button-hover-color: ${ p.theme.colors.black };
      --button-hover-border-color: ${ p.theme.colors.black };
    ` }
    
    ${ p => p.$color === 'black' && css`
      --button-color: ${ p.theme.colors.black };
      --button-hover-color: ${ p.theme.colors.green400 };
      --button-hover-border-color: ${ p.theme.colors.green400 };
    ` }
    
    ${ p => p.$color === 'darkGreen' && css`
      --button-color: ${ p.theme.colors.green700 };
      --button-hover-color: ${ p.theme.colors.black };
      --button-hover-border-color: ${ p.theme.colors.black };
    ` }

    display: ${ p => p.$fullWidth ? 'flex' : 'inline-flex' };
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 40px;
    padding: calc( 0.72em - 1px ) 1.2rem;
    /* white-space: nowrap; */
    border-radius: 100rem;
    border: 1px solid var( --button-color );
    
    ${ p => p.theme.typo.medium };
    font-size: ${ p => p.theme.typo.sizes[ p.$size ] };
    text-decoration: none;
    transition: 
      border-color .3s ease-out,
      background-color .3s ease-out,
      color .3s ease-out;

    ${ p => p.$style === 'filled' && css`
      background: var( --button-color );
      color: ${ p.theme.colors.realWhite };

      &:hover {
        border-color: var( --button-hover-border-color );
        background-color: var( --button-hover-color );
        color: var( --button-hover-text-color );
      }
    ` }

    ${ p => p.$style === 'outline' && css`
      background-color: transparent;
      color: var( --button-color );

      &:hover {
        background-color: var( --button-color );
        color: ${ p.theme.colors.realWhite };
      }
    ` }

    svg {
      height: calc( 1em * 0.8 );
      
      &:first-child {
        margin-right: 0.6rem;
      }
      &:last-child {
        margin-left: 0.6rem;
        margin-right: -0.3rem;
      }

      path {
        fill: currentColor;
      }
    }
  }
`

StyledButton.defaultProps = {
  $style: 'filled',
  $size: 'normal',
  $fullWidth: false,
}

export default Button

export const StyledButtonGroup = styled.div<{
  $flexDirection?: 'row' | 'row-reverse';
}>`
  display: flex;
  flex-direction: ${ p => p.$flexDirection };
  justify-content: ${ p => p.$flexDirection === 'row' ? 'flex-start' : 'flex-end' };
  flex-wrap: wrap;
  gap: calc( var( --grid-gutter ) / 2 );
`

StyledButtonGroup.defaultProps = {
  $flexDirection: 'row',
}
