export function EVCharger() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9471 1.6911C18.1574 1.59114 18.3952 1.56479 18.6223 1.61627C18.8494 1.66775 19.0525 1.79408 19.1992 1.97496C19.3458 2.15584 19.4274 2.38076 19.4308 2.61358L19.4309 2.62283L19.4308 5.337L21.2997 5.43502C21.4699 5.4443 21.6352 5.49531 21.7811 5.58355C21.9269 5.6718 22.0488 5.79457 22.136 5.94104C22.2232 6.08752 22.273 6.25319 22.281 6.42346C22.289 6.59372 22.2551 6.76335 22.1821 6.91739L19.7028 12.1263L19.7023 12.1274C19.6016 12.3401 19.4316 12.5122 19.2202 12.6156C19.0085 12.7192 18.7678 12.7476 18.5377 12.6962C18.3076 12.6448 18.1019 12.5166 17.9544 12.3327C17.8069 12.1489 17.7263 11.9203 17.726 11.6845L17.726 8.96944L15.8591 8.87153C15.6887 8.86266 15.5224 8.8119 15.3763 8.7238C15.2299 8.63557 15.1076 8.51259 15.0202 8.36575C14.9328 8.21892 14.883 8.05278 14.8753 7.88209C14.8675 7.71149 14.9019 7.54161 14.9755 7.3875L17.4627 2.16737C17.5661 1.95878 17.7368 1.79106 17.9471 1.6911ZM18.1608 3.65466V5.93957C18.1608 6.27734 18.4253 6.55601 18.7626 6.5737L20.8861 6.68508L18.996 10.6563V8.36687C18.996 8.0291 18.7315 7.75044 18.3942 7.73275L16.2708 7.62138L18.1608 3.65466ZM18.5544 11.584L18.5553 11.5823L18.5544 11.584Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.9477 7.8342C2.9477 7.4835 3.232 7.1992 3.5827 7.1992H5.22606C5.82941 7.1992 6.40805 7.43888 6.83468 7.86552C7.26131 8.29215 7.50099 8.87079 7.50099 9.47413C7.50099 10.0775 7.26131 10.6561 6.83468 11.0828C6.40805 11.5094 5.82941 11.7491 5.22606 11.7491H3.5827C3.232 11.7491 2.9477 11.4648 2.9477 11.1141V7.8342ZM4.2177 8.4692V10.4791H5.22606C5.49258 10.4791 5.74819 10.3732 5.93665 10.1847C6.12512 9.99627 6.23099 9.74066 6.23099 9.47413C6.23099 9.20761 6.12512 8.952 5.93665 8.76354C5.74819 8.57508 5.49258 8.4692 5.22606 8.4692H4.2177Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.71777 10.7041C1.71777 10.3534 2.00207 10.0691 2.35277 10.0691H3.58272C3.93342 10.0691 4.21772 10.3534 4.21772 10.7041C4.21772 11.0548 3.93342 11.3391 3.58272 11.3391H2.35277C2.00207 11.3391 1.71777 11.0548 1.71777 10.7041Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.71777 8.24422C1.71777 7.89352 2.00207 7.60922 2.35277 7.60922H3.58272C3.93342 7.60922 4.21772 7.89352 4.21772 8.24422C4.21772 8.59492 3.93342 8.87922 3.58272 8.87922H2.35277C2.00207 8.87922 1.71777 8.59492 1.71777 8.24422Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4044 4.30252C12.6843 4.02258 13.064 3.86531 13.4599 3.86531C13.8106 3.86531 14.0949 4.14961 14.0949 4.50031C14.0949 4.85101 13.8106 5.13531 13.4599 5.13531C13.4008 5.13531 13.3442 5.15878 13.3024 5.20054C13.2607 5.24231 13.2372 5.29895 13.2372 5.35802V20.9168C13.2372 20.9758 13.2607 21.0325 13.3024 21.0743C13.3442 21.116 13.4008 21.1395 13.4599 21.1395H19.9441C20.0032 21.1395 20.0598 21.116 20.1016 21.0743C20.1434 21.0325 20.1668 20.9758 20.1668 20.9168V14.2215C20.1668 13.8708 20.4511 13.5865 20.8018 13.5865C21.1525 13.5865 21.4368 13.8708 21.4368 14.2215V20.9168C21.4368 21.3127 21.2796 21.6923 20.9996 21.9723C20.7197 22.2522 20.34 22.4095 19.9441 22.4095H13.4599C13.064 22.4095 12.6843 22.2522 12.4044 21.9723C12.1245 21.6923 11.9672 21.3127 11.9672 20.9168V5.35802C11.9672 4.96213 12.1245 4.58245 12.4044 4.30252Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.65783 10.1855C7.53577 10.135 7.40497 10.1091 7.27289 10.1091L6.86258 10.1091C6.51187 10.1091 6.22758 9.82484 6.22758 9.47414C6.22758 9.12344 6.51187 8.83914 6.86258 8.83914H7.27223C7.57097 8.83903 7.86716 8.89774 8.14322 9.01192C8.4194 9.12615 8.67036 9.29365 8.8818 9.50486C9.09324 9.71608 9.261 9.96687 9.37551 10.2429C9.49003 10.519 9.54905 10.8149 9.5492 11.1137L9.5492 18.4946C9.5492 18.7611 9.65508 19.0168 9.84354 19.2052C10.032 19.3937 10.2876 19.4996 10.5541 19.4996H12.6023C12.953 19.4996 13.2373 19.7839 13.2373 20.1346C13.2373 20.4853 12.953 20.7696 12.6023 20.7696H10.5541C9.95079 20.7696 9.37215 20.5299 8.94552 20.1032C8.51888 19.6766 8.2792 19.098 8.2792 18.4946V11.1144C8.27914 10.9823 8.25305 10.8515 8.20244 10.7295C8.15183 10.6075 8.07769 10.4967 7.98424 10.4034C7.8908 10.31 7.77988 10.236 7.65783 10.1855Z" />
    </svg>
  )
}
