import ACCORDION_FRAGMENT from './pageSegments/accordion'
import CARDS_FRAGMENT from './pageSegments/cards'
import CONTACT_FRAGMENT from './pageSegments/contact'
import EXPERTS_FRAGMENT from './pageSegments/experts'
import FOOTER_FRAGMENT from './pageSegments/footer'
import INFO_POINTS_FRAGMENT from './pageSegments/infoPoints'
import LARGE_POSTCARDS_FRAGMENT from './pageSegments/largePostcards'
import LOGO_GRID_FRAGMENT from './pageSegments/logoGrid'
import POSTCARDS_FRAGMENT from './pageSegments/postcards'
import PACKAGES_FRAGMENT from './pageSegments/packages'
import PARTNER_LOGOS_FRAGMENT from './pageSegments/partnerLogos'
import PROCESS_STEPS_FRAGMENT from './pageSegments/processSteps'
import PROCESS_STEPS_SIMPLE_FRAGMENT from './pageSegments/processStepsSimple'
import PROPERTIES from './pageSegments/properties'
import PROPERTY_LISTINGS_FRAGMENT from './pageSegments/propertyListings'
import STORY_FRAGMENT from './pageSegments/story'
import TESTIMONIALS_FRAGMENT from './pageSegments/testimonials'
import TEXT_CTA_FRAGMENT from './pageSegments/textCta'
import VIDEO_CTA_FRAGMENT from './pageSegments/videoCta'
import HIGHLIGHTS_FRAGMENT from './pageSegments/highlights'
import FLOORPLAN_FRAGMENT from './pageSegments/floorplan'
import LOCAL_AREA_FRAGMENT from './pageSegments/localArea'
import COLUMNS_FRAGMENT from './pageSegments/columns'
import VIDEO_GROUP_FRAGMENT from './pageSegments/videoGroup'

const PAGE_SEGMENTS_FRAGMENT = `
pageSegments {
  ${ ACCORDION_FRAGMENT }
  ${ CARDS_FRAGMENT }
  ${ CONTACT_FRAGMENT }
  ${ EXPERTS_FRAGMENT }
  ${ FOOTER_FRAGMENT }
  ${ INFO_POINTS_FRAGMENT }
  ${ LARGE_POSTCARDS_FRAGMENT }
  ${ LOGO_GRID_FRAGMENT }
  ${ POSTCARDS_FRAGMENT }
  ${ PACKAGES_FRAGMENT }
  ${ PARTNER_LOGOS_FRAGMENT }
  ${ PROCESS_STEPS_FRAGMENT }
  ${ PROCESS_STEPS_SIMPLE_FRAGMENT }
  ${ PROPERTIES }
  ${ PROPERTY_LISTINGS_FRAGMENT }
  ${ STORY_FRAGMENT }
  ${ TESTIMONIALS_FRAGMENT }
  ${ TEXT_CTA_FRAGMENT }
  ${ VIDEO_CTA_FRAGMENT }
  ${ HIGHLIGHTS_FRAGMENT }
  ${ FLOORPLAN_FRAGMENT }
  ${ LOCAL_AREA_FRAGMENT }
  ${ COLUMNS_FRAGMENT }
  ${ VIDEO_GROUP_FRAGMENT }
}
`

export default PAGE_SEGMENTS_FRAGMENT
