import IntlProvider from './IntlProvider'
import ThemeProvider from '../styles/ThemeProvider'
import { CraftProvider } from '../lib/craft'
import { CookieConsentProvider } from './CookieConsent'
// import { ModalProvider } from '../lib/modal'
import { BodyScrollProvider } from '../hooks/usePreventBodyScroll'
import BrixelMetaTags from './BrixelMetaTags'
// import Modal from './Modal'

function ContextProviders( { hostname, pageProps, children } ) {
  return (
    <CraftProvider 
      hostname={ hostname }
      pageProps={ pageProps }
      MetaTagsComponent={ BrixelMetaTags }
    >
      <ThemeProvider>
        <BodyScrollProvider>
          <IntlProvider>
            <CookieConsentProvider>
              {/* <ModalProvider Modal={ Modal }> */}
              { children }
              {/* </ModalProvider> */}
            </CookieConsentProvider>
          </IntlProvider>
        </BodyScrollProvider>
      </ThemeProvider>
    </CraftProvider>
  )
}

export default ContextProviders
