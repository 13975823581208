import {
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
  useCallback,
} from 'react'
import { setCookie, getCookie } from './cookies'
import type { ConsentObject } from '../gtm'

type Context = {
  userOptions: Array<{
    handle: string,
    name: string,
    initial: boolean,
    acceptAll: boolean,
    rejectAll: boolean,
    canEdit: boolean,
  }>,
  userPreferences: ConsentObject,
  openPreferences: () => void,
  openBanner: () => void,
  close: () => void,
  setUserPreferences: Dispatch<SetStateAction<any[]>>,
  currentView: 'banner' | 'preferences' | null,
}

const defaultContext: Context = {
  userOptions: [],
  userPreferences: {
    adConsentGranted: false,
    analyticsConsentGranted: false,
    functionalityConsentGranted: true,
    securityConsentGranted: true,
    personalizationConsentGranted: true,
  },
  openPreferences: () => {},
  openBanner: () => {},
  close: () => {},
  setUserPreferences: () => {},
  currentView: null,
}

const CookieCutterContext = createContext( defaultContext )

export const useCookieCutter = () => useContext( CookieCutterContext )

const getInitialUserPreferences = userOptions => {
  const cookie = getCookie()

  return cookie || userOptions.map( option => ( {
    handle: option.handle,
    setting: option.initial,
  } ) )
    .reduce( 
      ( prefsObj, pref ) => ( {
        ...prefsObj,
        [ pref.handle ]: pref.setting,
      } ),
      {}, 
    )
}

const hasUserInteracted = () => !!getCookie()

const accept = userOptions => {
  const userPrefs = userOptions.map( option => ( {
    handle: option.handle,
    setting: option.acceptAll,
  } ) )
    .reduce( 
      ( prefsObj, pref ) => ( {
        ...prefsObj,
        [ pref.handle ]: pref.setting,
      } ),
      {}, 
    )

  setCookie( userPrefs )
  return userPrefs
}

const reject = userOptions => {
  const userPrefs = userOptions.map( option => ( {
    handle: option.handle,
    setting: option.rejectAll,
  } ) )
    .reduce( 
      ( prefsObj, pref ) => ( {
        ...prefsObj,
        [ pref.handle ]: pref.setting,
      } ),
      {}, 
    )

  setCookie( userPrefs )
  return userPrefs
}

function CookieCutter( {
  userOptions,
  children,
} ) {
  const [currentView, setCurrentView] = useState( null )
  const [userPreferences, setUserPreferences] = useState( getInitialUserPreferences( userOptions ) )
  const openPreferences = useCallback( () => setCurrentView( 'preferences' ), [] )
  const openBanner = useCallback( () => setCurrentView( 'banner' ), [] )
  const close = useCallback( () => setCurrentView( null ), [] )

  const urlHash = typeof window !== 'undefined'
    ? window?.location?.hash
    : null

  const context = useMemo( () => ( {
    userOptions,
    userPreferences,
    openPreferences,
    openBanner,
    close,
    setUserPreferences,
    currentView,
  } ), [
    userOptions,
    userPreferences,
    openPreferences,
    openBanner,
    close,
    setUserPreferences,
    currentView,
  ] )

  useEffect( () => {
    if ( !hasUserInteracted() ) {
      setCurrentView( 'banner' )
    }

    setUserPreferences( getInitialUserPreferences( userOptions ) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify( userOptions )] )

  useEffect( () => {
    if ( urlHash === '#cc-test' ) {
      setCurrentView( 'banner' )
    }
  }, [urlHash] )

  return (
    <CookieCutterContext.Provider value={ context }>
      { children }
    </CookieCutterContext.Provider>
  )
}

export default CookieCutter

export function AcceptButton( { children } ) {
  const { userOptions, close, setUserPreferences } = useCookieCutter()

  const onClick = () => {
    const userPrefs = accept( userOptions )
    setUserPreferences( userPrefs )
    close()
  }

  return (
    <button
      type="button"
      onClick={ onClick }
    >
      { children }
    </button>
  )
}

export function RejectButton( { children } ) {
  const { userOptions, close, setUserPreferences } = useCookieCutter()

  const onClick = () => {
    const userPrefs = reject( userOptions )
    setUserPreferences( userPrefs )
    close()
  }

  return (
    <button
      type="button"
      onClick={ onClick }
    >
      { children }
    </button>
  )
}

export function SettingsButton( { children } ) {
  const { openPreferences } = useCookieCutter()

  return (
    <button
      type="button"
      onClick={ openPreferences }
    >
      { children }
    </button>
  )
}
