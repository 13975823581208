const COLUMNS_FRAGMENT = `
...on pageSegments_columns_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  textColumns {
    ... on textColumns_BlockType {
      id
      columnHeading
      columnContent
    }
  }
}
`

export default COLUMNS_FRAGMENT
