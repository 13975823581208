import LINK_IT_FRAGMENT from '../linkIt'

const PROCESS_STEPS_SIMPLE_FRAGMENT = `
...on pageSegments_processStepsSimple_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  bodyText
  button {
    ${ LINK_IT_FRAGMENT }
  }
  processStepsSimple {
    ...on processStepsSimple_BlockType {
      id
      stepText
    }
  }
}
`

export default PROCESS_STEPS_SIMPLE_FRAGMENT
