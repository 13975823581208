import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { grantAnalytics, denyAnalytics } from '../lib/gtm'
import Container from './layout/Container'
import CookieCutter, {
  AcceptButton,
  RejectButton,
  useCookieCutter,
} from '../lib/cookie-cutter/CookieCutter'
import { StyledButtonGroup, StyledButton } from './Button'
import { textLink } from '../styles/links'
import { useCraft } from '../lib/craft'

export function CookieConsentProvider( { children } ) {
  return (
    <CookieCutter
      userOptions={ [
        {
          handle: 'adConsentGranted',
          name: 'Advertising',
          initial: false,
          acceptAll: false,
          rejectAll: false,
          canEdit: false,
        },
        {
          handle: 'analyticsConsentGranted',
          name: 'Analytics',
          initial: false,
          acceptAll: true,
          rejectAll: true,
        },
        {
          handle: 'functionalityConsentGranted',
          name: 'Essential Functionality',
          initial: true,
          acceptAll: true,
          rejectAll: true,
          canEdit: false,
        },
        {
          handle: 'personalizationConsentGranted',
          name: 'Personalization',
          initial: true,
          acceptAll: true,
          rejectAll: false,
          canEdit: false,
        },
        {
          handle: 'securityConsentGranted',
          name: 'Security',
          initial: true,
          acceptAll: true,
          rejectAll: true,
          canEdit: false,
        },
      ] }
    >
      { children }
      <CookieConsentBanner />
    </CookieCutter>
  )
}

export function CookieConsentBanner() {
  const { currentView, userPreferences } = useCookieCutter()
  const craftData = useCraft()
  const cookieBannerContent = craftData?.pageProps?.cookieBanner?.[ 0 ]?.cookieBanner?.[ 0 ]

  useEffect( () => {
    if ( userPreferences.analyticsConsentGranted ) {
      grantAnalytics()
    }
    else {
      denyAnalytics()
    }
  }, [userPreferences] )

  if ( 
    currentView !== 'banner' 
    || !cookieBannerContent 
    || !cookieBannerContent.acceptButtonLabel
    || !cookieBannerContent.rejectButtonLabel
  ) {
    return null
  }

  return (
    <StyledCookieConsent>
      <Container>
        <StyledCookieConsentInner>
          <StyledCopy>
            <h3>{ cookieBannerContent.titleCopy }</h3>
            <div 
              // eslint-disable-next-line react/jsx-props-no-multi-spaces, react/no-danger
              dangerouslySetInnerHTML={ { __html: cookieBannerContent.bodyCopy } } 
            />
          </StyledCopy>
          <StyledButtonGroup>
            <StyledButton
              $color="green"
            >
              <AcceptButton>
                { cookieBannerContent.acceptButtonLabel }
              </AcceptButton>
            </StyledButton>
            <StyledButton
              $color="green"
              $style="outline"
            >
              <RejectButton>
                { cookieBannerContent.rejectButtonLabel }
              </RejectButton>
            </StyledButton>
          </StyledButtonGroup>
        </StyledCookieConsentInner>
      </Container>
    </StyledCookieConsent>
  )
}

const StyledCookieConsent = styled.div`
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${ p => p.theme.colors.white };
  border-top: 1px solid ${ p => p.theme.colors.grey300 };

  ${ p => p.theme.isGrid && css`
    border-top: 0;

    * {
      font-family: 'GeneralSans-Variable' !important;
    }

    a {
      color: inherit !important;
    }

    ${ StyledButton } button {
      --button-color: ${ p.theme.colors.black } !important;
      --button-hover-color: #f9f674;
      --button-hover-border-color: #f9f674;
      --button-hover-text-color: ${ p.theme.colors.black } !important;
      font-size: 14px;
      font-family: 'GeneralSans-Variable';
    }
  ` } 
`

const StyledCookieConsentInner = styled.div`
  padding: 2.5rem 0;
  color: ${ p => p.theme.colors.black };

  ${ StyledButton } {
    display: block;
    margin-top: 1.5rem;
  }

  @media all and ( max-width: 700px ) {
    flex-wrap: wrap;

    &:first-child {
      flex-grow: 1;
    }
  }
`

const StyledCopy = styled.div`
  max-width: 52rem;

  h3 {
    ${ p => p.theme.typo.semiBold }
    font-size: ${ p => p.theme.typo.sizes.medium };
  }
  
  p {
    font-size: ${ p => p.theme.typo.sizes.dropped };
  }

  > * {
    margin: 0 0 calc( 3 * var( --stack-basis ) ) 0;
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    ${ p => textLink( p.theme ) }
  }
`
