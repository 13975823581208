const VIDEO_GROUP_FRAGMENT = `
...on pageSegments_videoGroup_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  videos {
    ... on videos_BlockType {
      id
      videoUrl
      videoTitle
      videoDescription
    }
  }
}
`;

export default VIDEO_GROUP_FRAGMENT;
