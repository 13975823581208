import { useState, useEffect, useContext } from 'react'
import { ThemeProvider as TP, ThemeContext } from 'styled-components'
import { useRouter } from 'next/router'
import useRouterLoading from '../hooks/useRouterLoading'
import { typo } from './fonts'
import colors, { getColor } from './colors'

const theme = {
  typo,
  colors,
  getColor,
  noJs: true,
  isGrid: false,
}

export const useTheme = () => useContext( ThemeContext )

function ThemeProvider( { children } ) {
  const { pathname } = useRouter()
  const [noJs, setNoJs] = useState( true )
  const isLoading = useRouterLoading()

  theme.isGrid = pathname.startsWith( '/_grid' )

  useEffect( () => {
    setNoJs( false )
  }, [] )

  return (
    <TP theme={ {
      ...theme, noJs, isLoading,
    } }
    >
      { children }
    </TP>
  )
}

export default ThemeProvider
