import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const useRouterLoading = () => {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState( false )
  const startLoading = () => setIsLoading( true )
  const stopLoading = () => setIsLoading( false )

  useEffect( () => {
    router.events.on( 'routeChangeStart', startLoading )
    router.events.on( 'routeChangeComplete', stopLoading )
    router.events.on( 'routeChangeError', stopLoading )
    return () => {
      router.events.off( 'routeChangeStart', startLoading )
      router.events.off( 'routeChangeComplete', stopLoading )
      router.events.off( 'routeChangeError', stopLoading )
    }
  } )

  return isLoading
}

export default useRouterLoading
