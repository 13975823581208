import IMAGE_FRAGMENT from '../image'

const LARGE_POSTCARDS_FRAGMENT = `
...on pageSegments_largePostcards_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  largeCardsSectionBGColor
  largePostcards {
    ...on largePostcards_BlockType {
      id
      heading
      bodyText
      cardColor
      image {
        ${ IMAGE_FRAGMENT }
      }
    }
  }
}
`

export default LARGE_POSTCARDS_FRAGMENT
