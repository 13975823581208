import IMAGE_FRAGMENT from '../image'

const LOGO_GRID_FRAGMENT = `
...on pageSegments_logoGrid_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  linkedLogos {
    ...on linkedLogos_BlockType {
      id
      logoLink
      logoImage {
        ${ IMAGE_FRAGMENT }
      }
    }
  }
}
`

export default LOGO_GRID_FRAGMENT
