const colors = {
  black: '#06262D',
  ivory: '#F7F6F5',
  white: '#F2F2F2',
  realWhite: '#FFFFFF',
  // Grey
  grey700: '#676C6E',
  grey400: '#A2A9AD',
  grey300: '#CFD2D3',
  grey200: '#EDEDED',
  grey100: '#F6F6F6',
  // Blue
  blue700: '#004D71',
  blue400: '#00C1DE',
  blue300: '#96DAEA',
  blue200: '#D7F0F1',
  blue100: '#E8F6F6',
  // Green
  green700: '#00615C',
  green400: '#009877',
  green300: '#95E1BF',
  green200: '#DCF2E8',
  green100: '#E7F5EF',
  // Red
  red700: '#72253D',
  red400: '#FF595A',
  red300: '#FFB1B9',
  red200: '#FFD8DC',
  red100: '#FFEBED',
  // Yellow
  yellow400: '#FFC368',
  // Status
  error: '',
  success: '',
  notice: '',
}

// export const gridColors = {
//   ...colors,
//   black: '#082328',

//   grey300: '#B3B8EE',
//   grey200: '#DEE0F5',
//   grey100: '#E9EBFC',

//   blue300: '#A5D9D0',
//   blue200: '#C2E6E8',
//   blue100: '#D4EFF0',

//   green700: '#26704C',
//   green400: '#6FC277',
//   green200: '#FDFBA1',
//   green100: '#FFFECB',

//   red200: '#FFD2E7',
//   red100: '#FFDFEE',
// }

// Status
colors.error = colors.red400
colors.success = colors.green400
colors.notice = colors.yellow400

export default colors

export type Colors = keyof typeof colors

export const getColor = ( color: string ) => {
  if ( colors[ color ] ) {
    return colors[ color ]
  }

  return color
}
