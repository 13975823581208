import { css } from 'styled-components'
import colors from './colors'

const textSizes = {
  small: '0.777rem', // 14px
  dropped: '0.888rem', // 16px
  normal: '1rem', // 18px
  medium: '1.222rem', // 22px
  large: '1.444rem', // 26px
  veryLarge: '1.777rem', // 32px
  smallHero: '2.444rem', // 44px
  hero: '3.111rem', // 56px
}

export type TextSizes = keyof typeof textSizes

export const typo = {
  regular: css`
    font-family: 'Poppins', sans-serif;
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
  `,
  medium: css`
    font-family: 'Poppins', sans-serif;
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
  `,
  semiBold: css`
    font-family: 'Poppins', sans-serif;
    font-weight:  600;
    font-style:   normal;
    font-stretch: normal;
  `,
  mono: css`
    font-family: 'Space Mono', monospace;
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
  `,
  heroTitle: css``,
  heroSubTitle: css``,
  smallMeta: css``,
  sizes: textSizes,
  urls: [
    'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Space+Mono:wght@400;700&display=swap" rel="stylesheet',
  ],
}

typo.heroTitle = css`
  ${ typo.semiBold }
  font-size: ${ typo.sizes.hero };
  line-height: 1.1;

  @media ( max-width: 850px ) {
    font-size: ${ typo.sizes.smallHero };
  }

  @media ( max-width: 400px ) {
    font-size: ${ typo.sizes.veryLarge };
  }
`

typo.heroSubTitle = css`
  ${ typo.regular }
  font-size: ${ typo.sizes.large };
  line-height: 1.2;

  @media ( max-width: 400px ) {
    font-size: ${ typo.sizes.medium };
  }
`

typo.smallMeta = css`
  ${ typo.regular }
  font-size: ${ typo.sizes.small };
  line-height: 1.25;
`

const fonts = css`
  html,
  body,
  input,
  textarea,
  select,
  button {
    ${ typo.regular }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${ colors.black };
    font-size: 18px;
    line-height: 1.4;

    @media ( max-width: 350px ) {
      font-size: 16px;
    }
  }

  sup {
    vertical-align: top;
    position: relative;
    top: -0.1em;
  }

  sub {
    vertical-align: bottom;
    position: relative;
    bottom: -0.1em;
  }
`

export default fonts
