import IMAGE_FRAGMENT from '../image'
import LINK_IT_FRAGMENT from '../linkIt'

const STORY_FRAGMENT = `
...on pageSegments_story_Entry {
  id
  anchorId
  typeHandle
  label
  mainTitle
  subtitle
  button {
    ${ LINK_IT_FRAGMENT }
  }
  mainImage {
    ${ IMAGE_FRAGMENT }
  }
}
`

export default STORY_FRAGMENT
