const PROCESS_STEPS_FRAGMENT = `
...on pageSegments_processSteps_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  bodyText
  processSteps {
    ...on processSteps_BlockType {
      id
      text1
      text2
      color
      highlight
    }
  }
}
`

export default PROCESS_STEPS_FRAGMENT
