import {
  createContext, useContext, useEffect, useState,
} from 'react'

const BodyScrollContext = createContext( null )
BodyScrollContext.displayName = 'BodyScrollContext'

const useBodyScrollContext = () => useContext( BodyScrollContext )

const usePreventBodyScroll = ( preventScroll, id ) => {
  const setIds = useBodyScrollContext()

  useEffect( () => {
    if ( preventScroll ) {
      setIds( s => Array.from( new Set( [...s, id] ) ) )
    }
    else {
      setIds( s => s.filter( i => i !== id ) )
    }
    
    return () => {
      setIds( s => s.filter( i => i !== id ) )
    }
  }, [id, preventScroll, setIds] )
}

export function BodyScrollProvider( { children } ) {
  const [ids, setIds] = useState( [] )

  useEffect( () => {
    if ( ids.length ) {
      document.body.style.overflowY = 'hidden'
    }
    else {
      document.body.style.overflowY = 'unset'
    }
    
    return () => {
      document.body.style.overflowY = 'unset'
    }
  }, [ids] )

  return (
    <BodyScrollContext.Provider value={ setIds }>
      { children }
    </BodyScrollContext.Provider>
  )
}

export default usePreventBodyScroll
