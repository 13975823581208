import styled, { css } from 'styled-components'

const getContainerCss = containerType => {
  switch ( containerType ) {
    case 'full':
      return css`
        width: 100vw;
        max-width: var( --container-max-width );
      `
    
    case 'small-margin':
      return css`
        width: calc( 100vw - ( var( --container-small-margin-width ) * 2 ) );
        max-width: var( --container-max-width );
      `
    
    default:
      return css`
        width: calc( 100vw - ( var( --container-margin-width ) * 2 ) );
        max-width: var( --container-max-width );
      `
  }
}

const Container = styled.div<{
  $containerType?: 'normal' | 'full' | 'small-margin',
  $maxWidth?: string,
}>`
  margin-left: auto;
  margin-right: auto;

  ${ p => getContainerCss( p.$containerType ) }

  ${ p => p.$maxWidth && css`
    max-width: ${ p.$maxWidth };
  ` }
`

Container.defaultProps = {
  $containerType: 'normal',
}

export default Container

export const Expander = styled.div`
  overflow: hidden;
  --margin-diff: calc( -1 * ( 100vw - 2000px ) );
  --negative-margin-width: calc( var( --margin-diff ) / 2 );
  margin-left: min( 0px, var( --negative-margin-width ) );
  /* margin-left: var( --negative-margin-width ); */
  margin-right: min( 0px, var( --negative-margin-width ) );
`
